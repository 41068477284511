import { useTracker } from '@archipro-website/tracker';
import type { ClickToRevealType } from '../component/click-to-reveal/ClickToReveal';
import { useSessionStorageState } from 'ahooks';

const DEFAULT_SUB_KEY = 'all';
export interface UseClickToRevealProps {
    revealType: ClickToRevealType;
    revealKey: string;
    revealSubKey?: string;
}

export const useClickToReveal = ({
    revealType,
    revealKey,
    revealSubKey = DEFAULT_SUB_KEY,
}: UseClickToRevealProps) => {
    const sessionKey = `reveal-${revealType}-${revealKey}`;
    const [revealedSubKeys, setRevealedSubKeys] = useSessionStorageState<
        string[]
    >(sessionKey, { defaultValue: [] });
    const { log } = useTracker();

    const onRevealClick = () => {
        log(`${revealType}Reveal`, {
            url: new URL(window.location.href),
        });
        if (revealedSubKeys.includes(revealSubKey)) return;
        setRevealedSubKeys([...revealedSubKeys, revealSubKey]);
    };

    return {
        revealed:
            revealedSubKeys.includes(revealSubKey) ||
            revealedSubKeys.includes(DEFAULT_SUB_KEY),
        onRevealClick,
    };
};
